import { styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MuiIconButton from "@mui/material/IconButton";
import Alert from "components/Alert";
import CircularProgress from "components/CircularProgress";
import {
  CANCEL_UPLOAD,
  CONFIRM_UPLOAD,
  COULDNT_READ_FILE,
  getAvatarLabel,
  NO_VALID_FILE,
  SELECT_AN_IMAGE,
  UPLOAD_PENDING_ERROR,
} from "components/constants";
import IconButton from "components/IconButton";
import { CheckIcon, CrossIcon } from "components/Icons";
import Sentry from "platform/sentry";
import React, { useRef, useState } from "react";
import { Control, useController } from "react-hook-form";
import { useMutation } from "react-query";
import { service } from "service";
import { ImageInputValues } from "service/api";

import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

interface ImageInputProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  id: string;
  initialPreviewSrc?: string;
  name: string;
  onSuccess?(data: ImageInputValues): Promise<void>;
}

interface AvatarInputProps extends ImageInputProps {
  type: "avatar";
  userName: string;
}

interface RectImgInputProps extends ImageInputProps {
  type: "rect";
  alt: string;
  grow?: boolean;
  height?: string;
  width?: string;
}

const StyledWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const FlexWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
}));

const ConfirmationButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "& > * + *": {
    marginTop: theme.spacing(1),
  },
}));

const StyledImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "grow",
})<{ grow: boolean | undefined }>(({ theme, grow }) => ({
  height: 100,
  [theme.breakpoints.up("md")]: {
    height: 200,
  },
  width: "100%",
  objectFit: "cover",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  ...(grow && { maxWidth: "100%", height: "auto" }),
}));

const StyledLabel = styled("label")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
}));

const StyledInput = styled("input")(({ theme }) => ({
  display: "none",
}));

export function ImageInput(props: AvatarInputProps | RectImgInputProps) {
  const { className, control, id, initialPreviewSrc, name } = props;
  //this ref handles the case where the user uploads an image, selects another image,
  //but then cancels - it should go to the previous image rather than the original
  const confirmedUpload = useRef<ImageInputValues>();
  const [imageUrl, setImageUrl] = useState(initialPreviewSrc);
  const [file, setFile] = useState<File | null>(null);
  const [readerError, setReaderError] = useState("");

  const mutation = useMutation<ImageInputValues, Error>(
    () =>
      file
        ? service.api.uploadFile(file)
        : Promise.reject(new Error(NO_VALID_FILE)),
    {
      onSuccess: async (data: ImageInputValues) => {
        field.onChange(data.key);
        setImageUrl(
          props.type === "avatar" ? data.thumbnail_url : data.full_url,
        );
        confirmedUpload.current = data;
        setFile(null);
        await props.onSuccess?.(data);
      },
    },
  );
  const isConfirming = !mutation.isLoading && file !== null;
  const { field } = useController({
    name,
    control,
    defaultValue: "",
    rules: {
      validate: () => !isConfirming || UPLOAD_PENDING_ERROR,
    },
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setReaderError("");
    if (!event.target.files?.length) return;
    const file = event.target.files[0];
    try {
      const base64 = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
      setImageUrl(base64);
      setFile(file);
    } catch (e) {
      Sentry.captureException(
        new Error((e as ProgressEvent<FileReader>).toString()),
        {
          tags: {
            component: "component/ImageInput",
          },
        },
      );
      setReaderError(COULDNT_READ_FILE);
    }
  };

  //without this, onChange is not fired when the same file is selected after cancelling
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleCancel = () => {
    field.onChange(confirmedUpload.current?.key ?? "");
    const imageUrl =
      props.type === "avatar"
        ? confirmedUpload.current?.thumbnail_url
        : confirmedUpload.current?.full_url;
    setImageUrl(imageUrl ?? initialPreviewSrc);
    setFile(null);
  };

  return (
    <StyledWrapper>
      {mutation.isError && (
        <Alert severity="error">{mutation.error?.message || ""}</Alert>
      )}
      {readerError && <Alert severity="error">{readerError}</Alert>}
      <FlexWrapper>
        <StyledInput
          aria-label={SELECT_AN_IMAGE}
          accept="image/jpeg,image/png,image/gif"
          id={id}
          type="file"
          onChange={handleChange}
          onClick={handleClick}
          ref={inputRef}
        />
        <StyledLabel htmlFor={id} ref={field.ref}>
          {props.type === "avatar" ? (
            <MuiIconButton component="span">
              <Avatar
                className={className}
                src={imageUrl}
                alt={getAvatarLabel(props.userName ?? "")}
                sx={{ "& img": { objectFit: "cover" } }}
              >
                {props.userName?.split(/\s+/).map((name) => name[0])}
              </Avatar>
            </MuiIconButton>
          ) : (
            <StyledImage
              className={className}
              src={imageUrl ?? "/img/imagePlaceholder.svg"}
              style={{ objectFit: !imageUrl ? "contain" : undefined }}
              alt={props.alt}
              width={props.width ?? DEFAULT_WIDTH}
              height={props.height ?? DEFAULT_HEIGHT}
              grow={props.grow}
            />
          )}
          {mutation.isLoading && <StyledCircularProgress />}
        </StyledLabel>
        {isConfirming && (
          <ConfirmationButtonContainer>
            <IconButton
              aria-label={CANCEL_UPLOAD}
              onClick={handleCancel}
              size="small"
            >
              <CrossIcon />
            </IconButton>
            <IconButton
              aria-label={CONFIRM_UPLOAD}
              onClick={() => mutation.mutate()}
              size="small"
            >
              <CheckIcon />
            </IconButton>
          </ConfirmationButtonContainer>
        )}
      </FlexWrapper>
    </StyledWrapper>
  );
}

export default ImageInput;
